import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  Section,
  Container,
  SectionTitle,
  ExploreMore,
} from "../../components/Section";
import PrimaryButton from "../../components/Button/PrimaryButton";
import ProductCardGridView from "../../components/ProductCardGridView";

const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  @media (min-width: 1600px) {
    margin: 0 -20px;
  }
`;
const ProductGridItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
  @media (min-width: 1200px) {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
  @media (min-width: 1600px) {
    padding: 0 20px;
  }
`;

const ProductSection = ({ data }) => {
  return (
    <Section
      ept="30px"
      epb="60px"
      xpt="20px"
      xpb="40px"
      pt="15px"
      pb="30px"
      bgColor="#fff"
      className="section-product"
    >
      <Container>
        <SectionTitle textAlign="center" mb="15px">Our Top-Selling Metal Buildings</SectionTitle>
        <ProductGrid>
          {data.map((item, i) => (
            <ProductGridItem key={i}>
              <ProductCardGridView product={item.node} />
            </ProductGridItem>
          ))}
        </ProductGrid>
        <ExploreMore mt="30px">
          <Link to="/shop-metal-buildings/">
            <PrimaryButton text="View More" />
          </Link>
        </ExploreMore>
      </Container>
    </Section>
  );
};

export default ProductSection;
