import React, { useState } from "react";
import { graphql, Link, navigate } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Section,
  SectionPageTitle,
  Container,
  SectionImageBkg,
  SectionTitle,
  SectionDescription,
  SectionLineDescription,
  BreadCrumb,
  ExploreMore,
} from "../components/Section";
import { ArrowLeftIcon } from "../components/Icons";
import QuotePopUpButton from "../components/QuotePopUpButton";
import ProductCardGridView from "../components/ProductCardGridView";
import DefaultButton from "../components/Button/DefaultButton";
import ProductSection from "../sections/Home/ProductSection";

const HeroBanner = styled.section`
  position: relative;
  padding: 74px 0 0;
  @media (min-width: 768px) {
    margin-top: 64px;
    height: calc(100vh - 64px);
    padding: 10px 0 0 0;
  }
  @media (min-width: 1200px) {
    margin-top: 100px;
    padding: 15px 0 0 0;
    height: calc(100vh - 100px);
  }
`;
const SectionImageBkgs = styled(SectionImageBkg)`
  @media (max-width: 767.98px) {
    position: inherit;
  }
`;

const HeroCaption = styled.div`
  text-align: center;
  margin: 0;
  padding: 20px 0px 30px 0px;
  border-bottom: 1px solid #eef0fd;
  @media (min-width: 768px) {
    padding: 0;
    border-bottom: 0;
  }
  @media (min-width: 1200px) {
    height: 100%;
  }
  .container {
    @media (min-width: 1200px) {
      height: calc(100% + 60px);
    }
  }
`;
const HeroButton = styled.div`
  margin: 0;
`;

const HeroTitle = styled(SectionPageTitle)`
  color: #373636;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  @media (min-width: 768px) {
    font-weight: 800;
  }
  @media (min-width: 1600px) {
    font-size: 70px;
    line-height: 70px;
  }
  @media (max-width: 575.98px) {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 10px;
  }

`;
// const HeroSubTitle = styled.strong`
//   display: block;
//   font-weight: 500 !important;
//   @media (max-width: 767.98px) {
//     font-size: 26px;
//     line-height: 36px;
//   }
// `;

const SectionCustom = styled(Section)`
  overflow: hidden;
  .container {
    @media (min-width: 1410px) {
      max-width: inherit;
      padding-right: 0;
      padding-left: 0;
    }
  }
`;
const Grid = styled.div`
  .grid-item {
    position: relative;
    @media (min-width: 1410px) {
      padding-right: calc((100% - 1350px) / 2);
      padding-left: 0;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      @media (min-width: 1410px) {
        padding-right: 0;
        padding-left: calc((100% - 1350px) / 2);
      }
      .grid-figure {
        padding: 0 0 10px 10px;
        @media (min-width: 1600px) {
          padding: 0 0 20px 20px;
        }
        &:after {
          bottom: 0;
          right: auto;
          left: 0;
          background-color: #e9242a;
          clip-path: polygon(0 0, 0% 100%, 100% 100%);
        }
      }
      .grid-content {
        &:after {
          right: auto;
          left: 0;
          clip-path: polygon(0px 0px, 100% 0, 0px 100%);
        }
      }
    }
    + .grid-item {
      margin-top: 40px;
      @media (min-width: 768px) {
        margin-top: 60px;
      }
      @media (min-width: 992px) {
        margin-top: 80px;
      }
      @media (min-width: 1200px) {
        margin-top: 100px;
      }
      @media (min-width: 1600px) {
        margin-top: 120px;
      }
    }
  }
`;
const GridItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  @media (min-width: 992px) {
    margin: 0 -30px;
  }
`;
const GridItemCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
  @media (min-width: 992px) {
    padding: 0 30px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  &.grid-content {
    &:after {
      @media (min-width: 1200px) {
        content: "";
        position: absolute;
        right: 0;
        width: 28px;
        height: 28px;
        background-color: #373636;
        clip-path: polygon(0 0, 100% 100%, 100% 0);
        top: -40px;
      }
      @media (min-width: 1600px) {
        top: -60px;
      }
    }
  }
`;

const Figure = styled.div`
  position: relative;
  margin-bottom: 30px;
  padding: 0 10px 10px 0;
  @media (min-width: 1600px) {
    padding: 0 20px 20px 0;
  }
  .gatsby-image-wrapper {
    position: relative;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.35) 0 30px 40px -20px;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 60%;
    background-color: #576be4;
    -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }
`;

const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  margin: 0 -10px;
  @media (min-width: 1600px) {
    margin: 0 -15px;
  }
`;
const ProductGridItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
  @media (min-width: 1200px) {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
  }
  @media (min-width: 1600px) {
    padding: 0 15px;
  }
`;

const ProductCategoryTemplate = ({ location, data }) => {
  const [limit, setLimit] = useState(6);
  const pageData = data.contentfulProductCategories;
  const slug = pageData.title.split(" ").join("-").toLowerCase();
  const schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "metal buildings",
        item: `https://www.engineeredmetalbuildings.com/${slug}/`,
      },
    ],
  };

  const loadMore = () => {
    if (pageData.title === "Metal Buildings") {
      navigate("/shop-metal-buildings/");
    } else {
      setLimit(limit + 6);
    }
  };

  let products = [];
  if (pageData.title === "Metal Buildings") {
    products = data.topSellingProducts.edges;
  } else {
    products = data.allContentfulProduct.edges;
  }

  const renderProducts = () => {
    if (pageData.title === "Metal Buildings") {
      return <ProductSection data={products} />;
    }

    return (
      <Section
        ept="30px"
        epb="60px"
        xpt="20px"
        xpb="40px"
        pt="15px"
        pb="30px"
        bgColor="#fff"
        className="section-product"
      >
        <Container>
          <SectionTitle textAlign="center" mb="15px">
            {pageData.productListHeading}
          </SectionTitle>
          <ProductGrid>
            {products.slice(0, limit).map((product, i) => (
              <ProductGridItem key={i} className="product-item">
                <ProductCardGridView product={product.node} />
              </ProductGridItem>
            ))}
          </ProductGrid>

          {limit < data.allContentfulProduct.edges.length && (
            <ExploreMore justifyContent="center">
              <button type="button" aria-label="button" aria-hidden="true">
                <DefaultButton
                  text={
                    pageData.title === "Metal Buildings"
                      ? "View More"
                      : "Load More"
                  }
                  size="xl"
                  onClick={() => loadMore()}
                />
              </button>
            </ExploreMore>
          )}
        </Container>
      </Section>
    );
  };

  return (
    <Layout location={location}>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        schemaMarkup={schema}
      />
      <HeroBanner>
        <Container className="container">
          <BreadCrumb className="black">
            <Link to="/">Home</Link>
            <span>{pageData.title}</span>
          </BreadCrumb>
        </Container>
        <SectionImageBkgs>
          <GatsbyImage
            image={pageData.heroImage.gatsbyImageData}
            alt="building-banner"
          />
        </SectionImageBkgs>
        <HeroCaption>
          <Container className="container">
            <HeroTitle>{pageData.title} {pageData.subTitle}</HeroTitle>
            {/* <HeroSubTitle className="h1">{pageData.subTitle}</HeroSubTitle> */}
            <HeroButton>
              <QuotePopUpButton
                thanksURL="/thank-you-get-quote"
                text="Get Quote"
                iconAfter={<ArrowLeftIcon />}
                btnStyle
              />
            </HeroButton>
          </Container>
        </HeroCaption>
      </HeroBanner>

      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-category"
      >
        <SectionLineDescription mb="0">
          <Container>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.description.childMarkdownRemark.html,
              }}
            />
          </Container>
        </SectionLineDescription>
      </Section>

      <SectionCustom
        ept="60px"
        epb="30px"
        xpt="40px"
        xpb="20px"
        pt="30px"
        pb="15px"
        bgColor="#fff"
        className="section-custom"
      >
        <Container className="container">
          <Grid className="grid">
            {pageData.sections.map((section, i) => (
              <GridItem className="grid-item" key={i}>
                <GridItemCol>
                  <Figure className="grid-figure">
                    <GatsbyImage
                      image={section.image.gatsbyImageData}
                      alt={section.title}
                    />
                  </Figure>
                </GridItemCol>

                <GridItemCol className="grid-content">
                  <SectionTitle>{section.title}</SectionTitle>
                  <SectionDescription>
                    <hr />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: section.description.childMarkdownRemark.html,
                      }}
                    />
                  </SectionDescription>
                </GridItemCol>
              </GridItem>
            ))}
          </Grid>
        </Container>
      </SectionCustom>

      {renderProducts()}
    </Layout>
  );
};

export default ProductCategoryTemplate;

export const pageQuery = graphql`
  query ProductCategoryTemplatePageQuery(
    $id: String!
    $title: String!
  ) {
    contentfulProductCategories(id: { eq: $id }) {
      metaTitle
      metaDescription
      title
      subTitle
      description {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
      sections {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED, quality: 50)
        }
      }
      productListHeading
    }
    allContentfulProduct(
      filter: { productCategories: { elemMatch: { title: { eq: $title } } } }
    ) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
    topSellingProducts: allContentfulProduct(
      sort: { fields: skuNumber, order: ASC }
      limit: 6
    ) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
